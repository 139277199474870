import { forwardRef, useEffect, useRef } from "react"
import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"
import { Content, Item, Portal, Root, Trigger } from "@radix-ui/react-dropdown-menu"
import PropTypes from "prop-types"

import tokens from "@ninjaone/tokens"
import { getTextSize, useMountedState } from "@ninjaone/utils"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

import { StyledHr } from "./Styled"
import Text from "./Text"

const getStylesByVariant = ({ theme, variant }) => {
  switch (variant) {
    case "compact":
      return `
        padding: ${tokens.spacing[2]};
        color: ${theme.colorTextWeak};

        &[data-highlighted] {
          color: ${theme.colorTextWeak};
        }
      `
    default:
      return `
      `
  }
}

const getDropdownWidth = ({ width }) => {
  switch (width) {
    case "match":
      return `
        width: var(--radix-dropdown-menu-trigger-width);
      `
    default:
      return ``
  }
}

const StyledDropdown = styled(Root)`
  user-select: none;
`

const StyledTrigger = styled(Trigger)`
  cursor: pointer;
  user-select: none;
  padding: 0;
  border: none;
  background-color: transparent;

  display: flex;
  gap: ${tokens.spacing[2]};
  align-items: center;

  ${({ asChild, theme }) => !asChild && `color: ${theme.colorTextAction}`};

  &:focus-visible {
    outline: 2px auto ${({ theme }) => theme.colorForegroundFocus};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colorBackgroundCtaDisabled};
    color: ${({ theme }) => theme.colorTextDisabled};
  }
`

const StyledMenuContent = styled(Content, {
  shouldForwardProp: prop => isPropValid(prop) || !["contentZIndex", "dropdownListHeight"].includes(prop),
})`
  padding: 0;
  z-index: ${({ contentZIndex }) => contentZIndex || 9998};
  background: ${({ theme }) => theme.colorBackgroundWidget};
  margin-top: 2px;
  min-width: 200px;
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};

  ${({ width }) => getDropdownWidth({ width })};
  ${({ dropdownListHeight }) => (dropdownListHeight ? `overflow-y: auto; max-height: ${dropdownListHeight};` : "")}
`

const StyledMenuItem = styled(Item, {
  shouldForwardProp: prop => isPropValid(prop),
})`
  &:not([data-disabled]) {
    cursor: pointer;
  }

  user-select: none;
  text-align: left;
  margin: ${tokens.spacing[1]};
  padding: ${tokens.spacing[3]} ${tokens.spacing[2]};
  border-radius: ${tokens.borderRadius[1]};
  color: ${({ usesTextColor, theme }) => (usesTextColor ? theme.colorTextStrong : theme.colorTextAction)};

  &:hover&:focus {
    outline: none;
  }

  &:focus {
    outline: 2px auto ${({ theme }) => theme.colorForegroundFocus};

    @supports (-moz-appearance: none) {
      outline-offset: -2px;
    }
  }

  &[data-highlighted] {
    background: ${({ theme }) => theme.colorForegroundHover};
    color: ${({ usesTextColor, theme }) => (usesTextColor ? theme.colorTextStrong : theme.colorTextAction)};
  }

  &[data-disabled] {
    color: ${({ theme }) => theme.colorTextDisabled};
  }

  ${({ theme, variant }) => getStylesByVariant({ theme, variant })}
`

const StyledDefaultMenuTitle = styled.div`
  padding: ${tokens.spacing[3]} 0 0 ${tokens.spacing[3]};
  user-select: none;
`

const StyledLink = styled.a`
  font-size: ${({ size }) => getTextSize(size)};
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`

const DefaultTitleComponent = ({ titleToken }) => (
  <StyledDefaultMenuTitle>
    <Text size="xs" color="colorTextWeakest" token={titleToken} lineHeight="13px" />
  </StyledDefaultMenuTitle>
)

const DropdownOption = ({
  action = () => {},
  disabled,
  id,
  isRed,
  Icon,
  labelToken,
  LabelComponent,
  onSelect = () => {},
  splitBefore,
  splitAfter,
  titleToken,
  type,
  TitleComponent,
  usesTextColor,
  variant,
  ...option
}) => {
  const ref = useRef(null)

  return (
    <>
      {splitBefore && <StyledHr color="colorBorderWeakest" />}
      {TitleComponent && <TitleComponent />}
      {!TitleComponent && titleToken && <DefaultTitleComponent titleToken={titleToken} />}
      {!TitleComponent && !titleToken && (
        <StyledMenuItem
          {...{ disabled, variant, usesTextColor }}
          data-ninja-dropdown-item=""
          onClick={e => e.stopPropagation()}
          onSelect={() => {
            type === "link" ? ref.current.click() : action?.({ ...option, labelToken })
            onSelect?.({ ...option, labelToken })
          }}
        >
          {Icon && (
            <Box display="inline-block" marginRight={tokens.spacing[2]}>
              <Icon color={isRed ? "colorTextDanger" : "inherit"} size={variant === "compact" ? "sm" : "md"} />
            </Box>
          )}
          {type === "link" && (
            <StyledLink ref={ref} size={variant === "compact" ? "sm" : "md"} target="_blank" {...option}>
              {labelToken}
            </StyledLink>
          )}
          {type !== "link" && LabelComponent && <LabelComponent />}
          {type !== "link" && (
            <Text
              as="span"
              token={labelToken}
              lineHeight="initial"
              size={variant === "compact" ? "sm" : "md"}
              color={isRed ? "colorTextDanger" : "inherit"}
            />
          )}
        </StyledMenuItem>
      )}
      {splitAfter && <StyledHr color="colorBorderWeakest" />}
    </>
  )
}

const DropdownContent = forwardRef(
  (
    {
      options,
      portal,
      parentBottom,
      variant,
      onSelect,
      PopoverContainer,
      alignRight,
      matchWidth,
      onEscapeKeyDown,
      onInteractOutside,
      dropdownListHeight,
      contentZIndex,
    },
    ref,
  ) => {
    // We multiply parentBottom by -1 because with Reach when we add positive values the element goes up.
    const sideOffset = !portal && typeof parentBottom === "number" ? parentBottom * -1 : undefined

    const _DropdownOptions =
      options?.length &&
      options.map(option => (
        <DropdownOption key={option.id || option.labelToken} {...{ ...option, variant, onSelect }} />
      ))

    const dropdownWidth = matchWidth ? "match" : "normal"

    // This is added to prevent issues with the DataTable
    const handleOnKeyDown = event => {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.stopPropagation()
      }

      if (event.key === "Escape") {
        onEscapeKeyDown()
        event.stopPropagation()
      }
    }

    return (
      <StyledMenuContent
        hideWhenDetached
        onKeyDown={handleOnKeyDown}
        align={alignRight ? "end" : "start"}
        {...{ sideOffset, ref, width: dropdownWidth, dropdownListHeight, onInteractOutside, contentZIndex }}
        onClick={e => {
          // prevent calling DataTable row action when clicking between action cell dropdown items
          e.stopPropagation()
        }}
      >
        {PopoverContainer ? <PopoverContainer>{_DropdownOptions}</PopoverContainer> : _DropdownOptions}
      </StyledMenuContent>
    )
  },
)

/**
 * The childIsButton prop should not be used, if the component that you are
 * passing as a child doesn't spread the props to the other component.
 * Read more about: https://github.com/radix-ui/primitives/issues/953#issuecomment-959005835
 */
export default function Dropdown({
  variant,
  options,
  children,
  className,
  contentZIndex,
  alignRight,
  matchWidth,
  onCloseAutoFocus,
  onEscapeKeyDown,
  onInteractOutside,
  parentBottom,
  portal = true /* Be careful when merging. Do not remove this prop. */,
  modal = false,
  childIsButton,
  PopoverContainer,
  isExternalOpening,
  onSelect = () => {},
  handleExternalClose,
  onOpenChange = () => {},
  dropdownListHeight = null,
  disabled,
}) {
  const [isDropdownOpen, setIsDrowpdownOpen] = useMountedState(false)

  const handleEscapeKeyDown = () => {
    setIsDrowpdownOpen(false)
    onOpenChange?.(false)

    onEscapeKeyDown?.()
  }

  useEffect(() => {
    if (isExternalOpening) {
      setIsDrowpdownOpen(isExternalOpening)
      onOpenChange?.(isExternalOpening)

      handleExternalClose?.()
    }
  }, [setIsDrowpdownOpen, handleExternalClose, isExternalOpening, onOpenChange])

  const _DropdownContent = (
    <DropdownContent
      {...{
        portal,
        variant,
        options,
        onSelect,
        alignRight,
        matchWidth,
        onCloseAutoFocus,
        onInteractOutside,
        parentBottom,
        PopoverContainer,
        dropdownListHeight,
        onEscapeKeyDown: handleEscapeKeyDown,
        contentZIndex,
      }}
    />
  )

  return (
    <StyledDropdown
      open={isDropdownOpen}
      onClick={e => e.stopPropagation()}
      {...{ className: `dropdown-target ${className ?? ""}`, modal }}
      onOpenChange={newDropdownState => {
        setIsDrowpdownOpen(newDropdownState)
        onOpenChange?.(newDropdownState)
      }}
    >
      {/** The children element should NOT be a button. */}
      <StyledTrigger
        aria-label={localized("Actions")}
        asChild={childIsButton}
        data-ninja-dropdown-trigger=""
        onClick={e => e.stopPropagation()}
        disabled={disabled}
      >
        {children}
      </StyledTrigger>

      {portal ? <Portal>{_DropdownContent}</Portal> : _DropdownContent}
    </StyledDropdown>
  )
}

Dropdown.propTypes = {
  variant: PropTypes.string,
  options: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  alignRight: PropTypes.bool,
  matchWidth: PropTypes.bool,
  parentBottom: PropTypes.bool,
  portal: PropTypes.bool,
  modal: PropTypes.bool,
  childIsButton: PropTypes.bool,
  PopoverContainer: PropTypes.elementType,
  isExternalOpening: PropTypes.bool,
  onSelect: PropTypes.func,
  handleExternalClose: PropTypes.func,
  onOpenChange: PropTypes.func,
  dropdownListHeight: PropTypes.string,
}
